(function ($) {
    
    function checkboxGroupOnChange(event) {
        var $this = $(this),
            $form = $this.closest('form'),
            $group = $form.find('[data-checkbox__group="' + $this.data('checkbox__group') + '"]'),
            $required = $form.find('[data-checkbox__required="' + $this.data('checkbox__group') + '"]');
            
        $required.val($group.filter(':checked').length > 0 ? "1" : "");
    }
    
    $(document)
    .on('change.checkbox', '[data-checkbox__group]', checkboxGroupOnChange);
    
}(jQuery));